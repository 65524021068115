.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.hero {
  background: linear-gradient(-45deg, #920806, #4d242c, #282634, #0a394b);
  background-size: 400% 400%;
  animation: gradient 150s ease infinite;
  min-height: 100vh;
}

@keyframes gradient {
  0% {
    background-position: 53% 50%;
  }
  50% {
    background-position: 50% 53%;
  }
  100% {
    background-position: 53% 50%;
  }
}


.glassMorph{
  background: rgba( 255, 255, 255, 0.15 );
  backdrop-filter: blur( 4px );
  -webkit-backdrop-filter: blur( 4px );
  border-radius: 10px;
  border: 1px solid rgba( 255, 255, 255, 0.18 );
}
.glassMorph2{
  background: rgba( 255, 255, 255, 0.15 );
  backdrop-filter: blur( 4px );
  -webkit-backdrop-filter: blur( 4px );
  border-radius: 10px;
}